<template>
  <div class="wrapper">
    <div class="header-container">
      <div class="left">
        <el-form inline class="search-header" :model="searchData" label-width="100px">
          <el-form-item label="操作人">
            <el-input style="width:300px" v-model="searchData.userName" placeholder="请输入姓名" size="small"></el-input>
          </el-form-item>
          <el-form-item label="操作时间">
            <el-date-picker
            size="small"
              style="width:300px"
              value-format="yyyy-MM-dd"
              v-model="searchData.timeSlot"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <el-button type="primary" icon="el-icon-search" size="small" @click="getList">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />
  </div>
</template>

<script>
import { getListAPI } from './api'

const columns = [
  {
    label: '操作人',
    prop: 'user',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['user']['userName']}</p>
    }
  },
  {
    label: '操作内容',
    prop: 'opSubject',
    minWidth: '130',
    customRender(h, row) {
      return (
        <p class="small-size" title={row['opSubject']}>
          {row['opSubject']}
        </p>
      )
    }
  },
  {
    label: 'IP',
    prop: 'ip',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['ip']}</p>
    }
  },
  {
    label: '操作时间',
    prop: 'createTime',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  }
]
export default {
  name: 'List',

  data() {
    return {
      isShow: false, //控制箭头
      columns,
      currentPage: 1,
      page_size: 20,
      total: 0,
      list: [],
      searchData: {
        userName: '',
        leftCreateDate: '',
        rightCreateDate: '',
        timeSlot: []
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      const { userName, timeSlot } = this.searchData
      let params = { userName, page: this.currentPage, pageSize: this.page_size }
      params.leftCreateDate = timeSlot?.[0] || undefined
      params.rightCreateDate = timeSlot?.[1] || undefined
      const res = await getListAPI(params)

      this.list = res.list || []
      this.total = res.total || 0
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left {
      flex: 1;
    }
    .right {
      margin-left: 20px;
    }
  }
  .search-header /deep/ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .arrow {
      text-align: right;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }

      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }

      .go {
        transform: rotate(-180deg);
      }
    }

    .el-form-item {
      width: 40%;
      min-width: 400px;
    }
  }

  .el-divider {
    margin: 10px 0;
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
    margin-right: 0;
  }
  .el-date-editor /deep/ {
    .el-range-separator {
      padding: 0;
    }
  }
}
</style>
